import "superfish"

$(function(){

    const lazyLoad=new LazyLoad({elements_selector: ".lazy"});
    lazyLoad.update();

    setTimeout(()=>{
        $("#map-iframe").attr("src","https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2980.7431193532984!2d25.604280515545128!3d45.664476028078326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b35b928884a05d%3A0x1451c6482ce316e0!2sSuper%20FM!5e1!3m2!1sen!2sro!4v1595531116146!5m2!1sen!2sro")
    },1000);

    $('.nav-menu').superfish({
        animation: {
            opacity: 'show'
        },
        speed: 400
    });

    const body=$("body");
    const header=$('#header');
    const navMenuContainer=$('#nav-menu-container');

    if ($(navMenuContainer).length) {
        const $mobile_nav = $(navMenuContainer).clone().prop({
            id: 'mobile-nav'
        });
        $mobile_nav.find('> ul').attr({
            'class': '',
            'id': ''
        });
        $(body).append($mobile_nav);
        $(body).prepend('<button type="button" id="mobile-nav-toggle"><i class="fas fa-bars"></i></button>');
        $(body).append('<div id="mobile-body-overly"></div>');
        $('#mobile-nav').find('.menu-has-children').prepend('<i class="fa fa-chevron-down"></i>');

        $(document).on('click', '.menu-has-children i', function(){
            $(this).next().toggleClass('menu-item-active');
            $(this).nextAll('ul').eq(0).slideToggle();
            $(this).toggleClass("fa-chevron-up fa-chevron-down");
        });

        $(document).on('click', '#mobile-nav-toggle', function() {
            $('body').toggleClass('mobile-nav-active');
            $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
            $('#mobile-body-overly').toggle();
        });

        $(document).click(function(e) {
            const container = $("#mobile-nav, #mobile-nav-toggle");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if ($(body).hasClass('mobile-nav-active')) {
                    $('body').removeClass('mobile-nav-active');
                    $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
                    $('#mobile-body-overly').fadeOut();
                }
            }
        });
    } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
        $("#mobile-nav, #mobile-nav-toggle").hide();
    }

    $('.nav-menu a, #mobile-nav a, .scrollto').on('click', function() {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            const target = $(this.hash);
            if (target.length) {
                let top_space = 0;

                if ($(header).length) {
                    top_space = $(header).outerHeight();

                    if (!$(header).hasClass('header-fixed')) {
                        top_space = top_space - 20;
                    }
                }

                $('html, body').animate({
                    scrollTop: target.offset().top - top_space
                }, 1500);

                if ($(this).parents('.nav-menu').length) {
                    $('.nav-menu .menu-active').removeClass('menu-active');
                    $(this).closest('li').addClass('menu-active');
                }

                if ($(body).hasClass('mobile-nav-active')) {
                    $(body).removeClass('mobile-nav-active');
                    $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
                    $('#mobile-body-overly').fadeOut();
                }
                return false;
            }
        }
    });
});